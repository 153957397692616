export default defineAppConfig({
    plugins: {
        sentry: {
            enabled: true,
        },
    },
    cart: {
        paymentReturnRouteName: 'cart-payment-summary', // TODO: add later
    },
    userMenu: {
        links: [
            {
                labelPath: 'user.my_account',
                routeName: 'customer-my-account',
                icon: 'IconPerson',
            },
            {
                labelPath: 'user.my_orders',
                routeName: 'customer-my-orders',
                icon: 'IconBox',
            },
            {
                labelPath: 'user.my_credits',
                routeName: 'customer-my-credits',
                icon: 'IconCoins',
            },
            {
                labelPath: 'user.favourite_products',
                routeName: 'customer-favourite-products',
                icon: 'IconHeart',
            },
            {
                labelPath: 'user.complaints',
                routeName: 'customer-complaints',
                icon: 'IconReturn',
            },
        ],
    },
    links: {
        socials: {
            facebook: 'https://www.facebook.com/monroobeauty',
            instagram: 'https://www.instagram.com/monroobeauty/',
            youtube: 'https://www.youtube.com/@monroobeauty',
        },
    },
})
